@use "overrides";

@tailwind base;
@tailwind components;
@tailwind utilities;

iframe {
  border: none;
  display: block;
  height: 100%;
  width: 100%;
}

// prevent flash of unstyled content with icon fonts
// https://github.com/angular/components/issues/12171

@supports not (-ms-high-contrast: none) {
  /* Non-IE styles here */
  body:not(.material-icons-loaded) {
    .mat-icon,
    mat-icon {
      opacity: 0 !important;
    }
  }

  body:not(.material-icons-outlined-loaded) {
    .mat-icon[fontSet="material-icons-outlined"],
    mat-icon[fontSet="material-icons-outlined"] {
      opacity: 0 !important;
    }
  }
}

@import "base/variables";

.error-list {
  list-style: disc inside;

  li {
    display: list-item;
  }
}

.cui-table {
  border-radius: $border-radius;
}

// TODO: Core UI move fieldset reset
fieldset {
  min-inline-size: auto;
}

h1:focus {
  outline-width: 0;
}

body {
  overflow: hidden;
}

.w-28 {
  width: 28rem;
}

.w-42 {
  width: 42rem;
}

.form-wrapper {
  max-width: 123rem;
}

@import "functions/cui-color";

$base-color: cui-color(gray, "300");
$ghost-color: cui-color(gray, "100");
$animation-duration: 1000ms;

@keyframes ghost-lines {
  0% {
    background-position: -100px;
  }
  40% {
    background-position: 100px;
  }
  100% {
    background-position: 500px;
  }
}

.ghost-line {
  animation: ghost-lines $animation-duration infinite linear;
  background-color: rgba(235, 235, 235, 0.1);
  background-image: linear-gradient(
    90deg,
    $base-color 0,
    $ghost-color 40px,
    $base-color 80px
  );
  background-size: 80vw;
  border-radius: 4px;
}

mat-form-field.form-ghost-line div.mat-mdc-form-field-infix::after {
  content: "";
  animation: ghost-lines $animation-duration infinite linear;
  background-color: rgba(235, 235, 235, 0.1);
  background-image: linear-gradient(
    90deg,
    $base-color 0,
    $ghost-color 40px,
    $base-color 80px
  );
  background-size: 80vw;
  border-radius: 4px;
  display: block;
  margin-top: -1.6rem;
  height: 1.6rem;
}

mat-dialog-container cui-spinner {
  z-index: 1001 !important;
}

button[mat-mdc-icon-button] mat-spinner {
  &.mat-mdc-progress-spinner circle,
  &.mat-mdc-progress-spinner circle {
    stroke: cui-color(gray, "700") !important;
  }
}

/* Activity Web Notes List */
cla-note-list-header {
  mat-tab-header.mat-mdc-tab-header {
    border-style: none !important;
  }
  .mat-mdc-tab {
    height: 3.6rem !important;
    margin-top: 0.8rem !important;
  }
}

cla-template {
  mat-tab-header {
    position: sticky !important;
    top: 0 !important;
  }
  cla-template-details .mat-mdc-form-field-infix {
    width: auto !important;
  }
  cla-template-build mat-toolbar.template-control-panel-footer {
    @media (min-width: 768px) {
      height: 5.6rem !important;
      padding: 0 1.2rem !important;
    }
  }
}

cla-activity cla-note {
  form
    :not(.associations-container.w-full):not(mat-divider):not(button.expand-toggle) {
    max-width: 120rem !important;
  }
}

.py-bl-3 {
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
}

.ql-editor strong {
  font-weight: bold;
}

cla-activity-feed-search-bar {
  height: 4.55rem !important;

  &.standalone {
    margin-right: 0.8rem;
    button[mat-flat-button] {
      height: 3.6rem;
      margin-top: 0.475rem;
    }
  }

  [matSuffix] {
    height: 4.5rem !important;
  }
  .mdc-notched-outline {
    z-index: 20 !important;
  }
  .cui-button-group {
    > .mat-mdc-outlined-button {
      &:focus {
        z-index: 11 !important;
      }
    }
  }

  cui-popover {
    position: relative;
    outline: none !important;
    transform: translate(35.6rem, -2rem) !important;
    z-index: 20;
    width: 0 !important;

    button[cui-popover-trigger],
    button[mat-button].popover-close {
      visibility: hidden !important;
    }

    .popover-inner-content {
      padding: 0 !important;
    }

    .popover-content {
      &--bottomRight {
        &::after,
        &::before {
          right: 7.3rem !important;
        }
      }
    }
  }
}

cla-activity-feed-filters-panel {
  &.standalone {
    > section {
      width: 35.6rem !important;
    }
    > section > form {
      height: 100%;
    }
    .footer-container {
      position: absolute;
      bottom: 0;
      width: 100%;
      &::before {
        content: none;
      }
    }
  }
}
cla-activity-feed {
  cla-activity-feed-header,
  cla-claim-summary-header {
    z-index: 2;
  }
}

mat-divider.cui-label {
  border-color: rgba(0, 0, 0, 0.6) !important;
  margin-top: 0.2rem !important;
  margin-bottom: 0.2rem !important;
}

.expand-toggle {
  font-family: $primary-typeface;
  align-items: center;
  border: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  cursor: pointer;
  display: flex;
  text-align: left;
  height: 3.6rem;
  align-self: flex-start;
  > span {
    width: 6.4rem;
  }
}

button[mat-stroked-button] mat-icon[matPrefix].text-2xl {
  margin-right: 1.4rem !important;
}

.break-words {
  word-break: break-word !important;
}

/*
These classes are in the tailwind documentation, but they are not working.
For now this is a temporary fix
https://tailwindcss.com/docs/flex-grow
*/
.grow {
  flex-grow: 1 !important;
}

.grow-0 {
  flex-grow: 0 !important;
}

.fit-content {
  max-width: fit-content;
}

.bg-transparent {
  background: transparent;
}

.border-none {
  border: none;
}

.cursor-pointer {
  cursor: pointer;
}
