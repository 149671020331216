mat-list-item {
  &.small {
    height: 3.2rem !important;
    font-size: 1.4rem !important;
  }
  > div.mdc-list-item__content {
    padding: 0 !important;
  }
}

mat-selection-list {
  outline: none !important;
}

.bulleted-list {
  list-style: disc inside;

  li {
    display: list-item;
  }
}
