quill-editor:not(.cdk-focused) {
  > div[quill-editor-element] {
    > div.ql-editor.ql-blank::before {
      display: none !important;
    }
  }
}

div[quill-editor-element] {
  outline: none !important;
  border: none !important;
  font-size: 14px;
  font-weight: 400;
  min-height: 24rem;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  display: flex;
  flex-direction: column;
  > div.ql-editor {
    padding: 0 0 0.8rem 0 !important;
    line-height: 1.125 !important;
    flex: 1 1 auto !important;
    &.ql-blank::before {
      font-style: inherit !important;
      left: 0 !important;
      color: rgba(0, 0, 0, 0.4) !important;
    }
    > ol,
    ul {
      padding-left: 0.5rem !important;
      display: flex;
      flex-direction: column;
      > li {
        width: 100%;
        &:first-of-type {
          margin-top: 0.5rem !important;
        }
        &:last-of-type {
          margin-bottom: 0.5rem !important;
        }
      }
    }
  }
}

div[quill-editor-toolbar] {
  border: none !important;
  padding: 0 !important;
  margin-left: -1rem;
  margin-right: -1rem;
  > span.ql-formats {
    padding: 0.6rem !important;
  }
  button {
    padding: 0 !important;
    width: 2.4rem !important;
    margin-right: 0.8rem;
    &:first-of-type {
      margin-right: 0.4rem;
    }
    mat-icon {
      vertical-align: middle;
    }
    &.cla-ql-highlight {
      mat-icon {
        line-height: 2.4rem;
        font-size: 1.8rem;
      }
    }
  }
}

mat-form-field.formatted-text .mat-mdc-form-field-infix {
  padding-bottom: 0 !important;
}

.quill-tooltip {
  font-size: 1.2rem !important;
}

.ql-tooltip {
  display: none !important;
}

cla-quill-form-field cui-popover {
  transform: translate(17.5rem, -7rem) !important;
  button[cui-popover-trigger] {
    visibility: hidden !important;
  }
}

$caret-size: 1rem;
$bottom-arrow-shadow: -1px -1px 4px -2px rgba(0, 0, 0, 0.2);

.link-preview {
  border-radius: 0.4rem;
  position: absolute;
  z-index: 100;

  &::after,
  &::before {
    background-color: inherit;
    content: "";
    height: $caret-size;
    position: absolute;
    transform: rotate(45deg);
    width: $caret-size;
    z-index: -1;
    left: 1rem;
    right: auto;
    top: -0.5rem;
  }
  &::after {
    box-shadow: $bottom-arrow-shadow;
  }
}
