.cdk-overlay-pane[style*="transform: translateX(-16px)"] {
  transform: translateX(-16px) translateY(-13px) !important;
}

.option-loading .mdc-list-item__primary-text {
  display: flex;
  justify-content: center;
}

mat-option.indeterminate > mat-pseudo-checkbox::after {
  top: 0.24rem !important;
  left: 0.2rem !important;
  width: 0.8rem !important;
  height: 0.3rem !important;
  opacity: 1 !important;
  box-sizing: content-box !important;
}
