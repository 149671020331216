form.condensed,
.condensed {
  .mat-mdc-form-field[appearance="outline"]:not(.expanded) {
    .mat-mdc-text-field-wrapper {
      padding-bottom: 0 !important;
      margin: 0 !important;
    }
    .mat-mdc-form-field-infix {
      width: auto !important;
      flex-grow: 1 !important;
    }
  }
}

.mat-mdc-form-field-icon-suffix {
  margin: 0 0.4rem !important;
  padding: 0 4px 0 0 !important;
}
