mat-tab-header.mat-mdc-tab-header {
  z-index: 10 !important;
}
mat-tab-group.z-index-unset {
  div.mat-mdc-tab-body-wrapper {
    mat-tab-body.mat-mdc-tab-body-active {
      z-index: unset !important;
    }
  }
}
.mat-mdc-tab-body-wrapper,
.mat-mdc-tab-body-content,
.mat-mdc-tab-body,
.mat-mdc-tab-body.mat-mdc-tab-body-active {
  overflow: visible !important;
}

.mat-mdc-tab-body:not(.mat-mdc-tab-body-active) {
  display: none !important;
}
