button.small {
  &[mat-button] {
    height: 3rem !important;
    line-height: 3rem !important;
    padding: 0.1rem 0.6rem !important;
  }
  &[mat-stroked-button].cui-bordered-icon-button {
    line-height: 1.8rem;
    padding: 0 0.4rem !important;
    min-width: auto !important;
    mat-icon {
      line-height: 1.4rem !important;
      font-size: 1.4rem !important;
      height: 1.4rem !important;
      width: 1.4rem !important;
    }
    &.rounded {
      border-radius: 50% !important;
    }
    &.thick {
      border-width: 0.2rem !important;
    }
  }
}
mat-button-toggle {
  button.mat-button-toggle-button {
    .mat-button-toggle-label-content {
      padding: 0 0.8rem !important;
      line-height: 4rem !important;
    }
  }
}

button[mat-mdc-outlined-button].add-fields-button {
  > span.mdc-button__label {
    width: 100% !important;
    min-width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
  }
  &.cdk-keyboard-focused {
    outline: none !important;
    > span.mdc-button__label {
      display: flex !important;
      justify-content: flex-start !important;
      align-items: center !important;
    }
    div.mat-mdc-focus-indicator {
      display: none !important;
    }
  }
}

.cui-button-group.split-button-group {
  outline: none !important;
  button.mat-mdc-outlined-button {
    padding: 0 !important;
    min-width: auto !important;
    &:first-of-type > span.mdc-button__label > mat-icon {
      margin: 0 0.1rem 0 0 !important;
    }
    &:last-of-type > span.mdc-button__label > mat-icon {
      margin: 0 0 0 0.1rem !important;
    }
  }
}

.search-btn {
  padding: 0 !important;
  min-width: 3.2rem !important;
  width: 3.2rem !important;
  height: 3.2rem !important;
  border-radius: 0.4rem !important;
  line-height: 3.2rem !important;

  mat-icon {
    height: 1.8rem !important;
    font-size: 1.8rem !important;
    line-height: normal !important;
    vertical-align: middle !important;
  }
}

.mat-mdc-icon-button.mat-mdc-button-base,
.mat-mdc-icon-button .mat-mdc-button-touch-target {
  &:not(.small) {
    width: 40px !important;
    height: 40px !important;
    padding: 0px !important;
  }

  &.small {
    width: 30px !important;
    height: 30px !important;
    padding: 0px !important;
  }
}

.mat-mdc-icon-button {
  .mat-icon {
    vertical-align: middle !important;
  }
  font-size: 14px !important;
}

.mat-mdc-form-field-icon-suffix .mat-mdc-icon-button.mat-mdc-button-base,
.mat-mdc-form-field-icon-suffix
  .mat-mdc-icon-button
  .mat-mdc-button-touch-target {
  width: 2.5em !important;
  height: 2.5em !important;
  justify-content: center !important;

  .mat-icon {
    font-size: 21px !important;
    height: 1.125em !important;
    line-height: 1.125 !important;
  }
}

.mdc-button__label {
  font-size: 14px !important;
}

.mat-mdc-button > .mat-icon {
  font-size: 1.8rem !important;
  width: 1.8rem !important;
  height: 1.8rem !important;
}

.copy-button {
  width: 100%;

  .mat-icon {
    margin-left: auto;
  }
}
